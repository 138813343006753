tr.groupHeader
	{
	cursor: pointer;

	td:before
		{
		content: "[-]";
		font-family: monospace;
		padding-right: 0.1em;
		}
	&.hidden td:before
		{
		content: "[+]";
		}
	}

.icon
	{
	cursor: pointer;
	}
